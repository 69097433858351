<template>
  <b-form-group :disabled="readOnly || !editable">
    <template #label>
      <div class="codex-field__field-name-container">
        <FieldName
          :name="name"
          :help-text="helpText"
          :help-text-display="helpTextDisplay"
          :required="required"
        >
          <template #top-left>
            <AiIcon
              v-if="configuration && configuration.aiFeatures && !readOnly"
              class="codex-field__ai-icon hide-in-quick-view"
              size="18"
              @click.native="showAiPopup = true"
            />
          </template>
          <template #top-right>
            <span
              v-if="showCharacterCount && validation.textLengthRange.isEnabled && VALUE_TYPES.SINGLE === valueType && (appearance === TEXT_FIELD_APPEARANCES.SINGLE_LINE || appearance === TEXT_FIELD_APPEARANCES.MULTIPLE_LINES)"
              :class="{'validation-invalid': !validationLabel(computedValue).isValid}"
              class="codex-field__count"
            >
              {{ validationLabel(computedValue).text }}
            </span>
            <span
              v-else-if="showCharacterCount && VALUE_TYPES.SINGLE === valueType && (appearance === TEXT_FIELD_APPEARANCES.SINGLE_LINE || appearance === TEXT_FIELD_APPEARANCES.MULTIPLE_LINES)"
              class="codex-field__count"
            >
              {{ $t('fields.codex-field-text.render.characters', { count: countCharacters(computedValue)}) }}
            </span>
          </template>
        </FieldName>
      </div>
    </template>
    <div
      class="codex-text-field__input-container"
      :class="{'codex-field-input-selected': VALUE_TYPES.SINGLE === valueType}"
    >
      <template v-if="VALUE_TYPES.SINGLE === valueType">
        <div v-if="appearance === TEXT_FIELD_APPEARANCES.SINGLE_LINE">
          <b-form-input
            v-model="computedValue"
            :data-prolexis="alias"
            :readonly="readOnly"
          />
        </div> <!-- SINGLE_LINE -->

        <div v-if="appearance === TEXT_FIELD_APPEARANCES.MULTIPLE_LINES">
          <b-form-textarea
            v-model="computedValue"
            :data-prolexis="alias"
            style="min-height: 55px;"
            :readonly="readOnly"
          />
        </div> <!-- MULTIPLE_LINES -->
        <div v-if="appearance === TEXT_FIELD_APPEARANCES.URL">
          <b-form-input
            v-model="computedValue"
            :readonly="readOnly"
          />
        </div> <!-- URL -->
        <b-form-group v-if="appearance === TEXT_FIELD_APPEARANCES.DROPDOWN">
          <v-select
            v-model="computedValue"
            :clearable="false"
            :options="validation.selectFrom.predefinedValues"
            class="text-wrap__inputs"
            :readonly="readOnly"
          />
        </b-form-group> <!-- DROPDOWN -->
        <div v-if="appearance === TEXT_FIELD_APPEARANCES.RADIO">
          <b-form-radio-group v-model="computedValue">
            <b-form-radio
              v-for="(val, idx) in validation.selectFrom.predefinedValues"
              :key="idx"
              :value="val"
              class="text-wrap__inputs"
              :disabled="readOnly"
            >
              {{ val }}
            </b-form-radio>
          </b-form-radio-group>
        </div> <!-- RADIO -->
        <div v-if="appearance === TEXT_FIELD_APPEARANCES.SLUG">
          <b-form-input
            v-model="computedValue"
            :readonly="readOnly"
          />
        </div> <!-- SLUG -->
      </template>

      <template v-else>
        <div
          v-if="appearance === TEXT_FIELD_APPEARANCES.SINGLE_LINE || appearance === TEXT_FIELD_APPEARANCES.MULTIPLE_LINES"
          class="text-field__inputs"
        >
          <div
            v-for="(input, index) in computedValue"
            :key="index"
            class="text-field__input-container"
          >
            <div class="text-field__input-align">
              <b-form-input
                v-if="appearance === TEXT_FIELD_APPEARANCES.SINGLE_LINE"
                v-model="computedValue[index]"
                :data-prolexis="`${alias}-${index}`"
                :readonly="readOnly"
              />
              <b-form-textarea
                v-else
                v-model="computedValue[index]"
                :data-prolexis="`${alias}-${index}`"
                :rows="2"
                no-resize
                :readonly="readOnly"
              />
              <span
                class="hide-in-quick-view"
                style="cursor: pointer; margin-left: 15px;"
                @click="handleDeleteInput(index)"
              >
                <GjIcon name="Close" />
              </span>
            </div>
            <FieldError :error="multilineError(index)" />
          </div> <!-- MULTIPLE_LINES -->
          <b-button
            variant="primary"
            size="sm"
            class="text-field__add-input hide-in-quick-view"
            @click="handleAddInput"
          >
            <GjIcon name="Plus" />
            {{ $t('fields.codex-field-text.render.add-input') }}
          </b-button>
        </div>

        <div v-if="appearance === TEXT_FIELD_APPEARANCES.TAG">
          <b-form-tags
            v-model="computedValue"
            input-id="tags-basic"
          />
        </div> <!-- TAG -->
        <div v-if="appearance === TEXT_FIELD_APPEARANCES.LIST">
          <b-form-input v-model="inputs" />
          <p class="text-field__unique-description mb-0">
            {{ $t('fields.codex-field-text.render.csv-hint') }}
          </p>
        </div>
        <!-- LIST -->
        <div v-if="appearance === TEXT_FIELD_APPEARANCES.CHECKBOX">
          <b-form-checkbox-group v-model="computedValue">
            <b-form-checkbox
              v-for="(val, idx) in validation.selectFrom.predefinedValues"
              :key="idx"
              :value="val"
              class="text-wrap__inputs"
            >
              {{ val }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </div>
        <!-- CHECKBOX -->
      </template>
      <AiPopup
        v-if="showAiPopup"
        :alias="alias"
        @close="showAiPopup = false"
        @setValue="setValue"
      />
    </div>
    <FieldError
      v-if="appearance === TEXT_FIELD_APPEARANCES.MULTIPLE_LINES || TEXT_FIELD_APPEARANCES.SINGLE_LINE && (!error.indexes || error.indexes.length === 0)"
      :error="error"
    />
    <FieldError
      v-else-if="VALUE_TYPES.LIST === valueType && !(appearance === TEXT_FIELD_APPEARANCES.SINGLE_LINE || appearance === TEXT_FIELD_APPEARANCES.MULTIPLE_LINES)"
      :error="error"
    />
  </b-form-group>
</template>

<script>
import FieldName from '@/components/fields/FieldName.vue'
import { generateComputedPropsFromAttrs } from '@/components/codex-layout-editor/BuilderUtils'
import {
  RANGE_OPERATORS, VALUE_TYPES, TEXT_FIELD_APPEARANCES, FIELD_FILTER_OPERATORS,
} from '@/views/models/constants'
import { enumMapping, toSlugV2 } from '@/utils/helpers'
import StringFilter from '@/components/filters-dropdown/filters/string/StringFilter'
import StringListFilter from '@/components/filters-dropdown/filters/stringList/StringListFilter'
import { debounce } from 'lodash'
import BaseFieldMixin from '@/components/fields/BaseFieldMixin'
import FieldRenderMixin from '@/components/fields/RenderFieldMixin'
import AiIcon from '@/components/ai/AiIcon.vue'
import AiPopup from '@/components/ai/AiPopup.vue'
import FieldError from '@/components/fields/FieldError.vue'

export default {
  name: 'TextField',
  inject: ['filterValues', 'entryId', 'toastNotification'],
  components: {
    FieldError,
    AiPopup,
    AiIcon,
    FieldName,
  },
  mixins: [BaseFieldMixin, FieldRenderMixin],
  props: {
    value: {
      type: [String, Array],
      default: null,
    },
  },
  data() {
    return {
      showAiPopup: false,
      VALUE_TYPES,
      TEXT_FIELD_APPEARANCES,
      RANGE_OPERATORS,
      setValue_: debounce(this.setValue, 500),
    }
  },
  computed: {
    ...generateComputedPropsFromAttrs([
      'name',
      'alias',
      'configured',
      'appearance',
      'validation',
      'valueType',
      'helpText',
      'helpTextDisplay',
      'defaultValue',
      'showCharacterCount',
      'configuration',
    ]),
    computedValue: {
      get() {
        return this.value
      },
      set(v) {
        this.setValue_(v)
      },
    },
    inputs: {
      get() {
        return this.value ? this.value.join(',') : ''
      },
      set(value) {
        this.computedValue = value.trim().split(',').map(v => v.trim())
      },
    },
  },
  watch: {
    computedValue(n, o) {
      if (this.valueType === VALUE_TYPES.SINGLE && this.appearance === TEXT_FIELD_APPEARANCES.SINGLE_LINE) {
        this.$root.$emit('text-field-input', { alias: this.alias, newValue: n, oldValue: o })
      }
    },
    valueType: {
      handler(v) {
        if (v === VALUE_TYPES.LIST && !Array.isArray(this.defaultValue)) {
          this.defaultValue = []
        } else if (v === VALUE_TYPES.SINGLE && Array.isArray(this.defaultValue)) {
          this.defaultValue = null
        }
      },
      immediate: true,
    },
  },
  beforeMount() {
    if (!this.widget.attrs.hidden) {
      this.$set(this.widget.attrs, 'hidden', {
        value: false,
        conditionsEnabled: false,
        conditions: [
          {
            isSystem: false,
            field: '',
            operator: FIELD_FILTER_OPERATORS.EXISTS,
            value: '',
          },
        ],
      })
    }
  },
  mounted() {
    if (this.entryId() === 'create' && !this.readOnly && this.editable) {
      // Prefill from filter
      if (this.filterValues && this.filterValues?.[this.alias]) {
        if (this.widget.attrs.valueType === VALUE_TYPES.SINGLE) {
          if (StringFilter.shouldApplyPredefinedValue(this.filterValues[this.alias])) {
            this.computedValue = this.filterValues[this.alias].value
          }
        } else if (StringListFilter.shouldApplyPredefinedValue(this.filterValues[this.alias])) {
          this.computedValue = [this.filterValues[this.alias].value]
        }
      }
    }
    // \Prefill from filter
    if (this.widget.attrs.valueType === VALUE_TYPES.SINGLE && this.widget.attrs.appearance === TEXT_FIELD_APPEARANCES.SLUG) {
      this.$root.$on('text-field-input', this.replaceSlug)
    }
    this.$root.$on('ai-generated-content', this.setAiGeneratedContent)
  },
  beforeDestroy() {
    this.$root.$off('text-field-input', this.replaceSlug)
    this.$root.$off('ai-generated-content', this.setAiGeneratedContent)
  },
  methods: {
    setAiGeneratedContent(alias, value, replace) {
      if (this.valueType === VALUE_TYPES.SINGLE && this.alias === alias && replace) {
        this.computedValue = value
        this.toastNotification({
          title: replace ? this.$t('site.add-site.replace') : this.$t('fields.general.ai-popup.results.retry-label'),
          text: this.$t('entries.ai-content-analyzer.seo.ai-suggestion-replaced'),
          variant: 'success',
          icon: 'Check',
        })
      }
    },
    setValue(value) {
      this.$emit('input', value)
    },
    countCharacters(str) {
      if (!str?.length) return 0
      return str.trim().length
    },

    replaceSlug(obj) {
      let replaceSlug = true
      if (obj?.oldValue && this.computedValue !== toSlugV2(obj.oldValue)) {
        replaceSlug = false
      }
      if (replaceSlug && obj.alias === this.widget.attrs.generateSlug && obj?.newValue) {
        this.computedValue = toSlugV2(obj.newValue)
      }
    },
    arrayIncludes(value, array = []) {
      return array.includes(value)
    },
    validate(value) {
      if (this.valueType === VALUE_TYPES.SINGLE) {
        return this.validateSingle(value)
      }
      return this.validateList(value)
    },
    validationLabel(value) {
      const { length, isValid } = this.validateTextLengthRange(value)
      const textLengthRange = this.validation.textLengthRange

      return {
        isValid: this.validation.required.value || this.countCharacters(value) > 0 ? isValid : true,
        text: this.$t(`fields.codex-field-text.render.validation-labels.${enumMapping(RANGE_OPERATORS).toString(textLengthRange.rangeOperator).toLowerCase()}`, {
          count: length,
          min: textLengthRange.min,
          max: textLengthRange.max,
          exactly: textLengthRange.exactly,
        }),
      }
    },
    validateTextLengthRange(value) {
      const textLength = this.countCharacters(value)
      const textLengthRange = this.validation.textLengthRange
      if (textLengthRange.isEnabled && (
        (textLengthRange.rangeOperator === RANGE_OPERATORS.GTE && textLength < textLengthRange.min)
          || (textLengthRange.rangeOperator === RANGE_OPERATORS.LTE && textLength > textLengthRange.max)
          || (textLengthRange.rangeOperator === RANGE_OPERATORS.BETWEEN && (textLength < textLengthRange.min || textLength > textLengthRange.max))
          || (textLengthRange.rangeOperator === RANGE_OPERATORS.EXACTLY && textLength !== textLengthRange.exactly)
      )) {
        return { isValid: false, message: textLengthRange.errorMessage, length: textLength }
      }
      return { isValid: true, length: textLength }
    },
    validateSingle(value) {
      const textLength = this.countCharacters(value)

      // Required
      if (this.required && !textLength) {
        return { isValid: false, message: this.validation.required.errorMessage }
      }
      // Range
      const rangeValidationForTypes = [TEXT_FIELD_APPEARANCES.SINGLE_LINE, TEXT_FIELD_APPEARANCES.MULTIPLE_LINES, TEXT_FIELD_APPEARANCES.SLUG]
      if (rangeValidationForTypes.includes(this.appearance) && textLength > 0) {
        return this.validateTextLengthRange(value)
      }
      if (this.appearance === TEXT_FIELD_APPEARANCES.URL && !!textLength) {
        const expression = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
        const regex = new RegExp(expression)
        if (!value.match(regex)?.length) {
          return { isValid: false, message: 'Please provide a URL' }
        }
        return { isValid: true }
      }
      // Matching Regex
      if (this.validation.matchingRegex.isEnabled && this.validation.matchingRegex.patterns.length && textLength) {
        let errors = false
        this.validation.matchingRegex.patterns.forEach(pattern => {
          const regex = new RegExp(pattern, this.validation.matchingRegex.flags.join(''))
          if (!regex.test(value)) {
            errors = true
          }
        })
        if (errors) return { isValid: false, message: this.validation.matchingRegex.errorMessage }
        return { isValid: true }
      }
      // prohibited Regex
      if (this.validation.prohibitedRegex.isEnabled && this.validation.prohibitedRegex.patterns.length && textLength) {
        let errors = false
        this.validation.prohibitedRegex.patterns.forEach(pattern => {
          const regex = new RegExp(pattern, this.validation.matchingRegex.flags.join(''))
          if (!regex.test(value)) {
            errors = true
          }
        })
        if (errors) return { isValid: false, message: this.validation.prohibitedRegex.errorMessage }
        return { isValid: true }
      }

      return { isValid: true }
    },
    validateList(value) {
      const listLength = value ? value.length : 0
      // Required
      if (this.required && listLength < 1) {
        return { isValid: false, message: this.validation.required.errorMessage }
      }
      // Range
      if (this.validation.listLengthRange.isEnabled && listLength > 0) {
        if (this.validation.listLengthRange.rangeOperator === RANGE_OPERATORS.GTE && listLength < this.validation.listLengthRange.min) {
          return { isValid: false, message: this.validation.listLengthRange.errorMessage }
        }
        if (this.validation.listLengthRange.rangeOperator === RANGE_OPERATORS.LTE && listLength > this.validation.listLengthRange.max) {
          return { isValid: false, message: this.validation.listLengthRange.errorMessage }
        }
        if (this.validation.listLengthRange.rangeOperator === RANGE_OPERATORS.BETWEEN && (listLength < this.validation.listLengthRange.min || listLength > this.validation.listLengthRange.max)) {
          return { isValid: false, message: this.validation.listLengthRange.errorMessage }
        }
        if (this.validation.listLengthRange.rangeOperator === RANGE_OPERATORS.EXACTLY && listLength !== this.validation.listLengthRange.exactly) {
          return { isValid: false, message: this.validation.listLengthRange.errorMessage }
        }
      }
      if (this.validation.textLengthRange.isEnabled && (this.appearance === TEXT_FIELD_APPEARANCES.SINGLE_LINE || this.appearance === TEXT_FIELD_APPEARANCES.MULTIPLE_LINES)) {
        const indexes = []
        for (let i = 0; i < value.length; i++) {
          const { isValid } = this.validateTextLengthRange(value[i])

          if (!isValid) {
            indexes.push(i)
          }
        }
        if (indexes.length > 0) {
          return { isValid: false, message: this.validation.textLengthRange.errorMessage, indexes }
        }
      }

      return { isValid: true }
    },
    handleAddInput() {
      if (this.readOnly || !this.editable) return
      if (!this.computedValue) {
        this.computedValue = []
      }
      this.computedValue.push('')
      this.$forceUpdate()
    },
    handleDeleteInput(idx) {
      if (this.readOnly || !this.editable) return
      this.computedValue.splice(idx, 1)
      this.$forceUpdate()
    },
    multilineError(index) {
      return {
        isValid: !this.error.isValid ? !this.arrayIncludes(index, this.error.indexes) : true,
        message: this.error.message,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.text-field__inputs {
  display: flex;
  flex-direction: column;
}

.text-field__add-input {
  width: fit-content;
  margin-left: auto;
}

.text-field__input-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.text-field__input-align {
  display: flex;
  flex-grow: 1;
  align-items: center;
}
.text-wrap__inputs{
  word-break: break-all;
}
.validation-invalid {
  color: #E34850;
}

// If AI is enabled in other fields, this style should be moved to the global styles
.codex-text-field__input-container {
  position: relative;
}
</style>
